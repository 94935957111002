<template>
  <div v-if="qualificationPlan" class="pa-5">
    <v-card>
      <v-card-title>Qualification Plan: {{ qualificationPlan.user.firstname }} {{ qualificationPlan.user.lastname }} ({{ qualificationPlan.year }}) - Status: {{ qualificationPlan.status }}
        <v-btn  @click="redirectToEdit()" icon>
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>

      </v-card-title>
      <!-- Actions -->
      <div class="pa-2 d-flex">
        <v-btn color="red" @click="deleteQualificationPlan">Delete Qualification Plan</v-btn>
        <v-select class="ml-2" placeholder="Select Action" dense v-model="selectedWorkflow"
                  item-text="name"
                  item-value="value"
                  :items="availableWorkflows"></v-select>
        <v-btn class="ml-2" :disabled="!canExecute" @click="executeWorkflow">Execute</v-btn>

      </div>

      <div class="pa-2 d-flex">
        <v-btn :disabled="!hasUploaded" @click="downloadUploaded">Download uploaded Plan</v-btn>
        <v-btn class="ml-2" @click="download('with')">Generate with Sign</v-btn>
        <v-btn class="ml-2" @click="download('without')">Generate without Sign</v-btn>
      </div>

      <!-- Info -->
      <div>
        <div v-if="hasAnyActivities">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left text-h5" style="width: 70%">
                  Activity
                </th>
                <th class="text-center text-h5" style="width: 10%">
                  Quantity
                </th>
                <th class="text-center text-h5" style="width: 10%">
                  Base CP
                </th>
                <th class="text-h5" style="width: 10%;">Proof</th>
              </tr>
              </thead>
            </template>
          </v-simple-table>

          <div v-for="category in categoryActivities">
            <div class=" ml-2 mt-2 text-subtitle-1 text-decoration-underline">{{ category.displayName }}</div>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr
                  v-for="item in category.activities"
                  :key="item.description + item.id"
                >
                  <td class="text-left" style="width: 70%">{{ item.description }}</td>
                  <td class="text-center" style="width: 10%">{{ item.number }}</td>
                  <td class="text-center" style="width: 10%">{{ item.cp }}</td>
                  <td style="width: 10%">
                    <v-btn @click="proofDownload(item.proofs)" icon v-if="item.proofs.length > 0"><v-icon>mdi-download</v-icon></v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div class="d-flex pt-4 pr-2">
            <v-spacer />
            <h3>Total CP: <span>{{ cpCount }}</span></h3>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router/composables";
import {CLIENT} from "@/api/client";
import {byApiId, byApi} from "@/api/helpers";
import QualificationPlanStatus
  from "@/modules/qualification-plan/component/qualification-plan-status/qualification-plan-status";
import {getAllCategories} from "@/modules/qualification-plan/api/api";

export default defineComponent({
  name: "se2a-qualification-plan-detail",
  components: {QualificationPlanStatus},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const qpId = computed(() => route.params.qpId);
    const qualificationPlan = ref();

    const fetchQualificationPlan = async () => {
      let response = await CLIENT.go(byApiId('qualification_plans', qpId.value)).get();
      qualificationPlan.value = response.asData();
    }

    const categories = ref([]);
    onMounted(() => {
      getAllCategories().then(result => {
        categories.value = result.asPaginationResult().membersData();
      })
    });

    const cpCount = computed(() => {
      return qualificationPlan.value.activities
        .map(activity => activity.number * activity.cp)
        .reduce((previousValue, currentValue ) => previousValue + currentValue, 0);
    });

    const categoryActivities = computed(() => {
      return categories.value.map(category => {
        const categoryActivities = qualificationPlan.value.activities
          .filter(activity => activity.category === category['@id']);

        if(categoryActivities.length === 0) {
          return null;
        }

        return {
          ...category,
          activities: categoryActivities
        }
      }).filter(x => !!x)
    });

    const hasAnyActivities = computed(() => {
      return qualificationPlan.value.activities.length > 0;
    });

    onMounted(fetchQualificationPlan);

    const deleteQualificationPlan = () => {
      CLIENT.go(qualificationPlan.value['@id']).delete().then(() => {
        router.push({name: 'admin-qualification-plan-index'});
      })
    }

    const selectedWorkflow = ref(null);
    const availableWorkflows = computed(() => {
      switch (qualificationPlan.value.status) {
        case 'review': return [{name: 'Needs Revision', value: 'revision'}, {name: 'Accept', value: 'reviewed'}];
        case 'in-process': return [{name: 'Force Review', value: 'submit'}];
        case 'signature': return [{name: "Finalize", value: 'submitFinalDocument'}];
        default: return [];
      }
    })

    const canExecute = computed(() => {
      return selectedWorkflow.value !== null;
    })

    const executeWorkflow = () => {
      CLIENT.go(byApi('qualification_plans/workflow')).post({
        iri: qualificationPlan.value['@id'],
        transition: selectedWorkflow.value
      }).then(result => {
        qualificationPlan.value = result.asData();
        selectedWorkflow.value = null;
      })
    }

    const download = (type) => {
      CLIENT.go(qualificationPlan.value['@id'] + '/sign').post({}).then(result => {
        window.open(qualificationPlan.value['@id'] + '/download?token=' + result.asData()['token'] + '&type=' + type, '_blank').focus();
      })
    }

    const proofDownload = (files) => {
      console.log(files);
      for (const file of files) {
        CLIENT.go(file['@id'] + '/sign').post({}).then(result => {
          window.open(file['@id'] + '/download?token=' + result.asData()['token'], '_blank').focus();
        })
      }
    }

    const hasUploaded = computed(() => {
      return qualificationPlan.value.signedFiles.length > 0;
    });

    const downloadUploaded = () => {
      for (const file of qualificationPlan.value.signedFiles) {
        CLIENT.go(qualificationPlan.value['@id'] + '/sign').post({}).then(result => {
          window.open(file['@id'] + '/download?token=' + result.asData()['token'], '_blank').focus();
        })
      }
    }
    const redirectToEdit = () => {
      router.push({name: "admin-qp-edit", params: {id: qualificationPlan.id}})
    }
    return {
      qualificationPlan, executeWorkflow, selectedWorkflow, availableWorkflows, canExecute,
      cpCount, categoryActivities, hasAnyActivities,
      deleteQualificationPlan, download, proofDownload, downloadUploaded, hasUploaded, redirectToEdit
    };
  }
});
</script>

<style scoped>

</style>
