
import {computed, defineComponent, ref} from "vue";

const STEP_MAP = {
  'in-process': 1,
  'review': 2,
  'signature': 3,
  'finished': 4
}

export default defineComponent({
  props: {
    status: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const currentStep = computed(() => STEP_MAP[props.status]);

    return {
      currentStep
    }
  }
})
